<template>
  <div class="handleLog">
    <el-row class="titleRow">
      <span class="line"></span>
      <span>风险点辨识</span>
    </el-row>
    <el-row class="selectRow">
      <div class="selectCol">
        <span>风险分级</span>
        <el-select v-model="value" placeholder="请选择">
          <el-option
            v-for="item in options"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          ></el-option>
        </el-select>
      </div>
      <div class="selectCol">
        <span>所属平台</span>
        <el-select clearable v-model="platId" placeholder="请选择">
          <el-option v-for="item in platList" :key="item.id" :label="item.name" :value="item.id"></el-option>
        </el-select>
      </div>
      <div class="selectCol">
        <span>企业名称</span>
        <el-input v-model="companyName" placeholder="请输入内容"></el-input>
      </div>
      <div class="selectCol">
        <span>风险点名称</span>
        <el-input v-model="danName" placeholder="请输入内容"></el-input>
      </div>
      <el-button @click="handleSearch" class="checkOutBtn">筛选</el-button>
    </el-row>
    <el-row class="tableRow">
      <el-table border :data="tableData" stripe style="width: 100%">
        <el-table-column align="center" prop="id" label="编号"></el-table-column>
        <el-table-column align="center" prop="company_name" label="企业名称">
          <template slot-scope="scope">
            <span :title="scope.row.company_name" class="overflowDoit">{{scope.row.company_name}}</span>
          </template>
        </el-table-column>
        <el-table-column align="center" prop="danger_name" label="风险点名称" width="120">
          <template slot-scope="scope">
            <span :title="scope.row.danger_name" class="overflowDoit">{{scope.row.danger_name}}</span>
          </template>
        </el-table-column>
        <el-table-column align="center" prop="danger_accident" label="易发生事故类型" width="250">
          <template slot-scope="scope">
            <span
              :title="scope.row.danger_accident"
              class="overflowDoit"
            >{{scope.row.danger_accident}}</span>
          </template>
        </el-table-column>
        <el-table-column align="center" label="风险分级">
          <template slot-scope="scope">
            <span v-if="scope.row.danger_level==1" style="color:blue">低风险</span>
            <span v-if="scope.row.danger_level==2" style="color:#e7ea45">一般风险</span>
            <span v-if="scope.row.danger_level==3" style="color:#f9972f">较大风险</span>
            <span v-if="scope.row.danger_level==4" style="color:red">重大风险</span>
          </template>
        </el-table-column>
        <el-table-column align="center" prop="position_id" label="部门">
          <template slot-scope="scope">
            <span :title="scope.row.position_id" class="overflowDoit">{{scope.row.position_id}}</span>
          </template>
        </el-table-column>
        <el-table-column align="center" label="负责人(联系方式)">
          <template slot-scope="scope">
            <span class="overflowDoit" :title="scope.row.charge_userid+'-'+scope.row.username">
              <span
                v-if="scope.row.charge_userid!='无'||scope.row.username!='无'"
              >{{scope.row.charge_userid+'-'+scope.row.username}}</span>
              <span v-if="scope.row.charge_userid=='无'&&scope.row.username=='无'">无</span>
            </span>
          </template>
        </el-table-column>
        <el-table-column align="center" label="操作" width="160">
          <template slot-scope="scope">
            <el-button @click="checkOut(scope.row)" class="tableBtn">查看详情</el-button>
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
        v-if="tableData.length>0"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="currentPage"
        :page-sizes="[3, 5, 10, 15, 20]"
        :page-size="pageSize"
        background
        layout="total, sizes, prev, pager, next, jumper"
        :total="totalCount"
      ></el-pagination>
    </el-row>
    <!-- 风险管控详情dia开始 -->
    <!-- :title="`风险点辨识详情(${userName}-${dangerName})`" -->
    <el-dialog
      :title="`风险点辨识详情(${dangerName})`"
      class="riskDia"
      :visible.sync="dialogDetail"
      width="40%"
      :before-close="dialogDetailClose"
    >
      <el-row class="tableRow">
        <el-form ref="form" :model="formData" label-width="160px">
          <el-form-item label="风险点名称">
            <el-input style="width:70%" disabled v-model="formData.danger_name"></el-input>
          </el-form-item>
          <el-form-item label="风险分级">
            <el-input style="width:70%" disabled v-model="formData.danger_level"></el-input>
          </el-form-item>
          <el-form-item label="所在区域">
            <el-input style="width:70%" disabled v-model="formData.danger_position"></el-input>
          </el-form-item>
          <el-form-item label="易发生事故类型" prop="desc">
            <el-input
              class="modifyInner"
              type="textarea"
              style="width:88%"
              disabled
              v-model="formData.danger_accident"
            ></el-input>
          </el-form-item>
          <el-form-item label="主要危险有害性" prop="desc">
            <el-input
              class="modifyInner"
              style="width:88%"
              type="textarea"
              disabled
              v-model="formData.reason"
            ></el-input>
          </el-form-item>
          <el-form-item label="措施" prop="desc">
            <el-input
              class="modifyInner"
              type="textarea"
              style="width:88%"
              disabled
              v-model="formData.danger_measures"
            ></el-input>
          </el-form-item>
          <el-form-item label="应急处置对策" prop="desc">
            <el-input
              class="modifyInner"
              style="width:88%"
              type="textarea"
              disabled
              v-model="formData.change_measures"
            ></el-input>
          </el-form-item>
        </el-form>
      </el-row>
    </el-dialog>
    <!-- 风险管控详情dia结束 -->
  </div>
</template>
<script>
export default {
  data() {
    return {
      platId: '',
      platList: [],
      userName: '',
      token: '',
      danName: '',
      // 企业信息
      infoVisibale: false,
      companyName: '',
      dialogDetail: false,
      dangerName: '',
      formData: {},
      options: [
        {
          value: '',
          label: '全部',
        },
        {
          value: 1,
          label: '低风险',
        },
        {
          value: 2,
          label: '一般风险',
        },
        {
          value: 3,
          label: '较大风险',
        },
        {
          value: 4,
          label: '重大风险',
        },
      ],
      value: '',
      startTime: '',
      currentPage: 1,
      totalCount: 0,
      pageSize: 20,
      tableData: [],
    }
  },
  methods: {
    handleSearch() {
      this.currentPage = 1
      this.getRiskList()
    },
    async getRiskList() {
      // return console.log('value', this.value)
      let param = new URLSearchParams()
      param.append('access_token', this.token)
      param.append('page', this.currentPage)
      param.append('length', this.pageSize)
      param.append('company_name', this.companyName)
      param.append('danger_name', this.danName)
      param.append('danger_level', this.value)
      param.append('platform_id', this.platId)
      const { data: res } = await this.$axios({
        method: 'post',
        url: '/api/danger/index',
        data: param,
      })
      if (res.code != 200) return this.$message.error(res.datas.msg)
      this.tableData = res.datas.list
      this.totalCount = parseInt(res.datas.count)
      console.log('风险点辨识数据', this.tableData)
      console.log('风险点辨识数据条数', this.totalCount)
    },
    async checkOut(row) {
      this.dangerName = row.danger_name
      let param = new URLSearchParams()
      param.append('access_token', this.token)
      param.append('id', row.id)
      param.append('is_mine', row.is_mine)
      const { data: res } = await this.$axios({
        method: 'post',
        url: '/api/Danger/detail',
        data: param,
      })
      if (res.code != 200) return this.$message.error(res.datas.msg)
      this.formData = res.datas
      if (this.formData.danger_level == '1') {
        this.formData.danger_level = '低风险'
      }
      if (this.formData.danger_level == '2') {
        this.formData.danger_level = '一般风险'
      }
      if (this.formData.danger_level == '3') {
        this.formData.danger_level = '较大风险'
      }
      if (this.formData.danger_level == '4') {
        this.formData.danger_level = '重大风险'
      }

      this.dialogDetail = true
    },
    dialogDetailClose() {
      this.dialogDetail = false
    },
    handleSizeChange(val) {
      this.pageSize = val
      this.getRiskList()
    },
    handleCurrentChange(val) {
      this.currentPage = val
      this.getRiskList()
    },
    async getPlatList() {
      let param = new URLSearchParams()
      param.append('access_token', this.token)
      const { data: res } = await this.$axios({
        method: 'post',
        url: '/api/user/platform',
        data: param,
      })
      if (res.code != 200) return this.$message.error(res.datas.msg)
      this.platList = res.datas
      console.log('平台列表', res)
    },
  },
  created: function () {
    this.token = localStorage.getItem('btlh_token') || ''
    this.userName = localStorage.getItem('btlh_nickname') || ''
    this.getPlatList()
    this.getRiskList()
  },
}
</script>
<style lang="less" scoped>
.handleLog {
  padding: 20px;
  background-color: #fff;
  // 标题行
  .titleRow {
    display: flex;
    align-items: center;
    font-size: 18px;
    font-weight: 700;
    margin-bottom: 20px;
    .line {
      display: block;
      width: 4px;
      height: 22px;
      margin-right: 10px;
      background-color: #333;
    }
  }
  // 企业选择行
  .selectRow {
    display: flex;
    font-size: 12px;
    margin-bottom: 15px;
    .el-date-editor.el-input,
    .el-input {
      width: 68%;
    }
    .selectCol {
      margin-right: 10px;
    }
    /deep/ .el-input__inner {
      font-size: 10px;
      height: 26px;
      width: 120px;
      border-radius: 0;
      margin-left: 10px;
      padding-left: 10px;
      padding-right: 10px;
    }
    /deep/ .el-input__icon {
      display: none !important;
    }
    .checkOutBtn {
      font-size: 12px;
      padding: 5px 20px;
      background-color: #447ed9;
      color: #fff;
    }
  }
  // 表格
  .tableRow {
    padding: 0 10px;
  }
  /deep/ .el-table {
    margin-top: 0;
    font-size: 12px;
    td {
      padding: 5px 0;
    }
    td,
    th.is-leaf {
      border-bottom: 0;
    }
    th {
      background-color: #f7f7f7;
      color: #222;
      font-weight: 700;
    }
    .el-table--striped .el-table__body tr.el-table__row--striped td {
      background-color: #f7f7f7;
    }
  }
  .el-table::before {
    display: none;
  }
  // 分页
  .el-pagination {
    display: flex;
    justify-content: flex-end;
    padding-right: 20px;
  }
}
.tableBtn {
  font-size: 12px;
  padding: 5px 10px;
  background-color: #447ed9;
  color: #fff;
}
/deep/ .el-dialog__header {
  .el-dialog__title {
    font-size: 14px;
  }
  // logo
  background: url(../../assets/images/smile.png) no-repeat;
  background-size: 20px;
  background-position: 35px 22px;
  padding-left: 65px;
}
/deep/ .el-dialog__body {
  display: flex;
  flex-wrap: wrap;
  padding-left: 60px;
  .el-button:nth-child(1) {
    margin-left: 10px;
  }
  .el-button:nth-child(odd) {
    width: 30%;
    margin-right: 6%;
  }
  .el-button:nth-child(even) {
    width: 50%;
  }
  .el-button {
    margin-bottom: 10px;
    text-align: center;
    font-size: 12px;
    padding: 10px 0px;
    background-color: #447ed9;
    color: #fff;
    font-size: 14px;
    font-weight: normal;
  }
}
.tableRow {
  padding: 0 10px;
  width: 100%;
}
/deep/ .el-table {
  margin-top: 0;
  font-size: 12px;
  // border: 1px solid #eee;
  td {
    padding: 5px 0;
  }
  td,
  th.is-leaf {
    border-bottom: 1px solid #eee !important;
  }
  th {
    background-color: #f7f7f7;
    color: #222;
    font-weight: 700;
  }
  .el-table--striped .el-table__body tr.el-table__row--striped td {
    background-color: #f7f7f7;
  }
}
.el-table::before {
  display: none;
}
// 分页
.el-pagination {
  display: flex;
  justify-content: flex-end;
  padding-right: 20px;
}
.riskDia {
  .tableBtnRisk {
    width: 100px !important;
    padding: 5px 10px !important;
    margin: 0 !important;
  }
  /deep/ .el-dialog__body {
    padding: 10px 20px 30px;
  }
  /deep/ .el-dialog__header {
    .el-dialog__title {
      font-size: 16px;
    }
  }
}
.riskDia {
  /deep/ .el-form-item {
    margin-bottom: 10px;
  }
  /deep/ .el-form-item__label {
    color: #000;
  }
  /deep/ .el-input__inner {
    height: 30px;
    border-radius: 0;
  }
  /deep/ .modifyInner textarea {
    border-radius: 0 !important;
    width: 80%;
    height: 70px;
  }
}

/deep/ .el-input.is-disabled .el-input__inner {
  background-color: #fff !important;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
/deep/ .el-textarea.is-disabled .el-textarea__inner {
  background-color: #fff !important;
  overflow: auto;
  text-overflow: ellipsis;
}
// 超出...的容器
.overflowDoit {
  width: 100%;
  white-space: nowrap; //不换行
  overflow: hidden; //超出隐藏
  text-overflow: ellipsis; //变成...
}
// 修改详情和编辑字体大小
/deep/ .el-form-item__label {
  font-size: 13px !important;
  color: #666 !important;
}
// 修改详情和编辑字体大小
/deep/ .el-input__inner,
/deep/.el-textarea__inner {
  font-size: 12px !important;
  color: #999 !important;
}

// 无数据露底
/deep/.el-table__empty-block {
  border-bottom: 1px solid #eee !important;
}
</style>